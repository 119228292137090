import './towerOfHanoi.css';
import Tower from './Tower';
import React, { useState } from 'react';
import useInterval from 'use-interval';
import { useSpring, config } from 'react-spring';

const createTowers = stones => {
  return {
    A: [...Array(stones).keys()].map(i => i+1),
    B: [],
    C: [],
  };
}

const clear = (stones, setTowers, setCurrent, setMovable) => {
  setTowers(createTowers(stones));
  setCurrent(0);
  setMovable(true);
}

const moveNext = (answers, current, towers, setTowers, setCurrent, setMovable, setAutoMove, setSelected) => {
  if (current < answers.length) {
    moveData(towers, setTowers, setSelected, ...answers[current]);
    setCurrent(current+1);
  }
  if (current >= answers.length - 1) {
    setMovable(false);
    setAutoMove(false);
  }
}

const movePrevious = (answers, current, towers, setTowers, setCurrent, setMovable, setAutoMove, setSelected) => {
  if (current > 0) {
    moveData(towers, setTowers, setSelected, ...answers[current-1].slice().reverse());
    setCurrent(current-1);
    setMovable(true);
  }
}

const switchAutoMove = (autoMove, setAutoMove) => {
  setAutoMove(!autoMove);
}

const moveData = (towers, setTowers, setSelected, from, to) => {
  console.log(from, to);
  setSelected(from);
  let newTower = Object.assign({}, towers);
  newTower[to].unshift(newTower[from][0]);
  newTower[from].shift();
  setTowers(newTower);
  setTimeout(()=>{
    setSelected(to);
    setTimeout(()=>{setSelected(null)}, 200);
  }, 300);
}

const h = (n, from, to, work) => {
  if(n === 1){
      return [[from,to]]
  } else {
      return h(n-1,from,work,to).concat(
        [[from,to]]).concat(
          h(n-1,work,to,from)
        );
  }
}

function Hanoi(props) {
  const [stones, setStones] = useState(5);
  const [towers, setTowers] = useState(createTowers(stones));
  const [answers, setAnswers] = useState(h(stones, "A", "C", "B"));
  const [current, setCurrent] = useState(0);
  const [movable, setMovable] = useState(true);
  const [autoMove, setAutoMove] = useState(false);
  const [selected, setSelected] = useState(null);
  const names = Object.keys(towers);
  const [springA, setSpringA] = useSpring(() => ({
    config: config.wobbly,
    backgroundColor: 'green',
  }));
  const [springB, setSpringB] = useSpring(() => ({
    config: config.wobbly,
    backgroundColor: 'green',
  }));
  const [springC, setSpringC] = useSpring(() => ({
    config: config.wobbly,
    backgroundColor: 'green',
  }));
  const springs = [springA, springB, springC];
  const prevMovable = current > 0;

  useInterval(() => {
    if (autoMove) {
      moveNext(answers, current, towers, setTowers, setCurrent, setMovable, setAutoMove, setSelected)
    }
  }, 1000);

  // アニメーション設定
  setSpringA({backgroundColor: selected === "A" ? 'green' : 'bisque'});
  setSpringB({backgroundColor: selected === "B" ? 'green' : 'bisque'});
  setSpringC({backgroundColor: selected === "C" ? 'green' : 'bisque'});

  return (
    <div id="hanoi">
      <div id="board">
        {names.map((item, idx) => (
          <Tower tower={towers[names[idx]]}
            id={names[idx]} key={idx}
            springStyle={springs[idx]} />
        ))}
      </div>
      <button onClick={() => movePrevious(answers, current, towers, setTowers, setCurrent, setMovable, setAutoMove, setSelected)}
        disabled={!prevMovable}
        >元に戻す</button>
      <button onClick={() => moveNext(answers, current, towers, setTowers, setCurrent, setMovable, setAutoMove, setSelected)}
        disabled={!movable}
        >次へ進める</button>
      <button onClick={() => clear(stones, setTowers, setCurrent, setMovable)} >クリアー</button>
      <button onClick={() => switchAutoMove(autoMove, setAutoMove)}
        >自動運転 {autoMove ? "停止" : "開始" }
      </button>
    </div>
  );
}

export default Hanoi;
