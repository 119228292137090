import React, { useState } from 'react';
import './towerOfHanoi.css';
import Disk from './Disk';
import { animated } from 'react-spring';

function Tower(props) {
  // console.log(props.springStyle);
  return (
    <animated.div className="tower" style={props.springStyle}>
      <div className="disk" id={props.id} >
        {props.tower.map((number, i) =><Disk key={i} number={number} />)}
      </div>
      <div className="bar"></div>
    </animated.div>
  );
}

export default Tower;
