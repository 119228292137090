import React, { useState } from 'react';
import './towerOfHanoi.css';

const diskNumber = (number) => {
  switch(number) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    default:
      return '';
  }
}

function Disk(props) {
  return (
    <div className={diskNumber(props.number)} />
  );
}

export default Disk;