import logo from './logo.svg';
import './App.scss';
import Hanoi from './Hanoi.jsx'
import { useState } from 'react'

function App() {
  const [title, setTitle] = useState('ハノイの塔')
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="title">
          <h1>{title}</h1>
        </div>
      </header>
      <div className="App-body">
        <Hanoi />
      </div>

    </div>
  );
}

export default App;
